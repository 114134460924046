import Sortable from 'stimulus-sortable';

export default class extends Sortable {
  static targets = ['order'];
  static values = {
    url: String,
  };

  connect() {
    super.connect();
    // The sortable.js instance.
    this.sortable;
    // Your options
    this.options;
    // Your default options
    this.defaultOptions;

    this.onUpdate = this.onUpdate.bind(this);
  }

  // Default options for the sortable instance
  get defaultOptions() {
    return {
      handle: this.handleValue,
      forceFallback: true,
      direction: 'vertical',
      onChoose: function (e) {
        e.target.classList.add('grabbing');
      },
      onUnchoose: function (e) {
        e.target.classList.remove('grabbing');
      },
    };
  }

  // Update the hidden input 'value' attribute in a form to have the current order (using index values)
  onUpdate(event) {
    const inputs = this.orderTargets;
    const endpoint = this.urlValue;

    if (this.hasOrderTarget) {
      // When an endpoint is used, send a request immediately to the API after drag 'n dropping
      if (endpoint) {
        // string[] which keeps track of the order using the item id's -> [4,5,2]
        const sequenceArray = inputs
          .filter((i) => i.value)
          .map((input) => input.value);

        fetch(endpoint, {
          method: 'PUT',
          body: JSON.stringify(sequenceArray),
        });
      } else {
        // When using a form submit instead, update the 'value' attribute for all hidden inputs in a form to have the current order (using index values) -> (0,1,2)
        inputs?.forEach((input, index) => (input.value = index));
      }
    } else {
      console.warn(
        'You are missing an attribute on your element: data-sortable-target="order"'
      );
    }

    super.onUpdate(event);
  }
}
