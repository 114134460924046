// Entry point for the build script in your package.json
import Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';
import '@hotwired/turbo-rails';
import '../controllers';
import '../stream_actions';

// remark components V1
import './rc_v1/main.css';
import * as Components from './rc_v1/main.js';
// Old CSS file which need to be removed, see: https://remarkgroup.atlassian.net/browse/VPD-3355
import '../styles/index.scss';

// remark components V2
import '@remark/components/build/react.js';
import '@remark/components/build/remark-login.css';
import '@remark/components/build/remark-login.js';

Rails.start();
ActiveStorage.start();

// NOTE: This file requires heavy refactoring, but since we are still using v1 during development we
// still need the config, see this ticket for updates: https://remarkgroup.atlassian.net/browse/VPD-3356

// alert developers if there is a mismatch between the required and installed version of remark components
// will log a message to the console
if (process?.env?.RAILS_ENV?.toString() === 'development') {
  let installedRCVersion = require('@remark/components/package.json');
  let requiredRCVersion = require('../../../package.json');
  if (installedRCVersion && requiredRCVersion) {
    installedRCVersion = parseInt(installedRCVersion.version.split('.').pop());
    requiredRCVersion = parseInt(
      requiredRCVersion.dependencies['@remark/components'].split('.').pop()
    );
    if (installedRCVersion < requiredRCVersion) {
      const cssRule =
        'color: rgb(233,96,93);' +
        'font-size: 32px;' +
        'font-weight: bold;' +
        'text-shadow: 1px 1px 5px rgb(233,96,93);' +
        'filter: dropshadow(color=rgb(233,96,93), offx=1, offy=1);';
      setTimeout(console.log.bind(console, '%cUpdate required!', cssRule), 0);
      const cssText = 'color: rgb(34,34,34);' + 'font-size: 14px;';
      setTimeout(
        console.log.bind(
          console,
          `%cInvalid version of remark components installed. Please run yarn install to install the correct version of remark components`,
          cssText
        ),
        0
      );
    }
  }
}

// V1 & V2 of components have a react clash
// This surpresses the warning
const originalConsoleError = console.error;

console.error = (...args) => {
  if (
    args
      .filter((arg) => typeof arg === 'string')
      .some(
        (arg) =>
          arg.includes('You are calling ReactDOMClient.createRoot()') ||
          arg.includes(
            'You are importing createRoot from "react-dom" which is not supported.'
          )
      )
  ) {
    // Ignore the warnings
    return;
  }

  // Call the original console.error function for other warnings/errors
  originalConsoleError.call(console, ...args);
};

/* Register module components */
const lib = (window.lib = global.lib = {});
Object.keys(Components).forEach((component) => {
  if (Components[component].displayName) {
    lib[Components[component].displayName] = Components[component];
  }
});

/* Register local components */
const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');

ReactRailsUJS.useContext(componentRequireContext);

function initialLoad() {
  Rails.refreshCSRFTokens();
  ReactRailsUJS.mountComponents(document.body);
}
document.addEventListener('turbo:load', initialLoad);

/* Register fonts */
document.addEventListener('DOMContentLoaded', () => {
  if (Components._breakpointsInit) {
    Components._breakpointsInit();
  }
  if (Components._webFontLoader) {
    Components._webFontLoader('google', null, [
      'Montserrat:400,500,600,700',
      'Roboto:400,500,700',
    ]);
  }
});

window.launchModal = function (content, closeModals = true) {
  if (closeModals && document.querySelector('.portal-node')) {
    document.querySelector('.portal-node').remove();
  }

  const container = document.createElement('div');
  container.innerHTML = content;
  document.body.appendChild(container);

  ReactRailsUJS.mountComponents(container);
  Rails.refreshCSRFTokens();
};

// If V1 components are added to a V2 component, the mount event needs to mount the components inside
document.addEventListener('rml-row:mount', (e) => {
  ReactRailsUJS.mountComponents(e.target);
});
document.addEventListener('rml-column:mount', (e) => {
  ReactRailsUJS.mountComponents(e.target);
});
document.addEventListener('rml-card:mount', (e) => {
  ReactRailsUJS.mountComponents(e.target);
});
document.addEventListener('rml-modal:mount', (e) => {
  ReactRailsUJS.mountComponents(e.target);
});
