import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  hideModal() {
    setTimeout(() => {
      const frame = this.element.closest('turbo-frame[id="v2-modal"]');

      if (frame) {
        //remove the src
        frame.removeAttribute('src');

        // Remove each child element inside the turbo-frame
        Array.from(frame.children).forEach((childElement) =>
          childElement.remove()
        );
      }
    }, 1);
  }

  // hide modal when ESC is pressed
  closeWithKeyboard(e) {
    if (e.code == 'Escape') {
      this.hideModal();
    }
  }

  //hide modal when background is clicked
  closeBackground(e) {
    // if (e && this.element.contains(e.target)) {
    //   return;
    // }
    // this.hideModal();
  }

  connect() {
    /*temporary solution to get the focus on the modal, there must be a better
    solution for this */
    this.element.click();

    this.element.addEventListener('turbo:submit-end', (event) => {
      if (event.detail.success) {
        this.hideModal();
      }
    });
  }
}
